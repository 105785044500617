import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import type { Theme } from 'src/theme';

interface CTAProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,

  },
  browseButton: {
    marginLeft: theme.spacing(2)
  }
}));

const CTA: FC<CTAProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        {/* <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          Ready to start cooking?
        </Typography> */}
        {/* <Typography
          variant="h1"
          align="center"
          color="secondary"
        >
          Download Devias Material Kit today.
        </Typography> */}

        
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            color="secondary"
            component="a"
            href="/login"
            variant="contained"
          >
            Join Today / Login
          </Button>
        </Box>
      </Container>
    </div>
  );
};

CTA.propTypes = {
  className: PropTypes.string
};

export default CTA;
