import axios from 'axios';

const axiosInstance = axios.create({
  baseURL:  process.env.REACT_APP_API_ENDPOINT,
  headers: { 'Content-Type': 'application/json' },
});

axiosInstance.interceptors.response.use(
  (response) => {
    //debugger;
    return response;
  },
  (error) => {
    //debugger
    Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
);

export default axiosInstance;
