import React from 'react';
import type { FC } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import Features from './Features';
import Testimonials from './Testimonials';
import CTA from './CTA';
import FAQS from './FAQS';
import Logo from 'src/components/Logo';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {},
  img: {
    width: '500px'
  }
}));

const HomeView: FC = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Mustard Mitts"
    >
      <br/>
      <br/>
   <Typography
  variant="h1"
  align="center"
  color="textPrimary"
        >
        Event Planning and Recipie tacking <br/>
        
      <CTA />
        <br/><br/>

        <img src="/static/pexels-pixabay-277253.jpg"  className={classes.img} />
        </Typography>


      {/* <Features /> */}
      {/* <Hero />       */}
      {/* <FAQS />  */}

      {/* <Features />
      <Testimonials />
      <CTA />
      <FAQS /> */}
    </Page>
  );
};

export default HomeView;
