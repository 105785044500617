import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
// import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import CommunityLayout from './layouts/CommunityLayout';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    path: '/app/community',    
    layout: CommunityLayout,
    routes: [
      {
        exact: true,
        path: '/app/community',
        component: lazy(() => import('src/views/recipe/CommunityRecipeListView'))
      },
      {
        exact: true,
        path: '/app/community/:customerId',
        component: lazy(() => import('src/views/recipe/CommunityRecipeView'))
      },
    ]
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [     
      {
        exact: true,
        path: '/app/recipe/:customerId/edit',
        component: lazy(() => import('src/views/recipe/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/recipe/:customerId',
        component: lazy(() => import('src/views/recipe/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/recipe',
        component: lazy(() => import('src/views/recipe/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/scrapbook',
        component: lazy(() => import('src/views/scrapbook/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/sessions',
        component: lazy(() => import('src/views/sessions/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/sessions/:customerId/edit',
        component: lazy(() => import('src/views/sessions/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/sessions/:customerId',
        component: lazy(() => import('src/views/sessions/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
  
      // {
      //   exact: true,
      //   path: '/app/management/customers/:customerId',
      //   component: lazy(() => import('src/views/customer/CustomerDetailsView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/customers/:customerId/edit',
      //   component: lazy(() => import('src/views/customer/CustomerEditView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/invoices',
      //   component: lazy(() => import('src/views/invoice/InvoiceListView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/invoices/:invoiceId',
      //   component: lazy(() => import('src/views/invoice/InvoiceDetailsView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/orders',
      //   component: lazy(() => import('src/views/order/OrderListView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/orders/:orderId',
      //   component: lazy(() => import('src/views/order/OrderDetailsView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/products',
      //   component: lazy(() => import('src/views/product/ProductListView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/products/create',
      //   component: lazy(() => import('src/views/product/ProductCreateView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management',
      //   component: () => <Redirect to="/app/management/customers" />
      // },
      // {
      //   exact: true,
      //   path: '/app/projects/overview',
      //   component: lazy(() => import('src/views/project/OverviewView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/projects/browse',
      //   component: lazy(() => import('src/views/project/ProjectBrowseView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/projects/create',
      //   component: lazy(() => import('src/views/project/ProjectCreateView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/projects/:id',
      //   component: lazy(() => import('src/views/project/ProjectDetailsView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/projects',
      //   component: () => <Redirect to="/app/projects/browse" />
      // },
      // {
      //   exact: true,
      //   path: '/app/reports/dashboard',
      //   component: lazy(() => import('src/views/reports/DashboardView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/reports/dashboard-alternative',
      //   component: lazy(() => import('src/views/reports/DashboardAlternativeView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/reports',
      //   component: () => <Redirect to="/app/reports/dashboard" />
      // },
      // {
      //   exact: true,
      //   path: '/app/social/feed',
      //   component: lazy(() => import('src/views/social/FeedView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/social/profile',
      //   component: lazy(() => import('src/views/social/ProfileView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/social',
      //   component: () => <Redirect to="/app/social/profile" />
      // },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/recipe" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      {
        exact: true,
        path: '/contact',
        component: lazy(() => import('src/views/contact/ContactView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
